export function sleep(ms: number) {
    return new Promise(r => setTimeout(r, ms));
}

// 페이지네이션 번호 계산
export function calculateStartEndPage(totalCount: number, perPage: number, current: number, showArraySize: number) {
    const totalPages = Math.ceil(totalCount / perPage);

    // 페이지네이션 시작 페이지 번호 계산
    const half = Math.floor(showArraySize / 2);
    let startPage = current - half;
    if (startPage < 1) {
        startPage = 1;
    }

    // 페이지네이션 끝 페이지 번호 계산
    let endPage = startPage + showArraySize - 1;
    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - showArraySize + 1);
    }

    return { startPage, endPage };
}

interface Node {
    id: number;
    parentId: number | null;
    [key: string]: any; // This will allow for other properties on the node
}

interface TreeNode {
    id: number;
    children: TreeNode[];
    [key: string]: any; // This will allow for other properties on the node
}

// list 형태의 tree 데이터를 parent-child 형태로 변환
/**
 * @example
 *  const data = [
 *      {
 *          'id': 2,
 *          'parentId': null,
 *      },
 *      {
 *          'id': 5,
 *          'parentId': 2,
 *      },
 *      {
 *          'id': 6,
 *          'parentId': 2,
 *      },
 *  ];
 *
 *  const tree = toTree(data);
 *  console.log(tree);
 * // returns [
 *      {
 *          id: 2,
 *          children: [
 *              {
 *                  id: 5,
 *                  children: [],
 *              },
 *              {
 *                  id: 6,
 *                  children: [],
 *              },
 *          ],
 *      }
 *  ]
 */
export function toTree(data: Node[], root: number | null = null): TreeNode[] {
    const nodes = data.filter(item => item.parentId === root);

    return nodes.map(node => {
        const { id, parentId, ...otherData } = node;
        const children = toTree(data, id);
        return { id, ...otherData, children };
    });
}

/**
 * @example
 * const tree = [
 *     {
 *          id: 2,
 *          children: [
 *              {
 *                  id: 5,
 *                  children: [],
 *              },
 *              {
 *                  id: 6,
 *                  children: [],
 *              },
 *          ],
 *      },
 * ]
 * const originalData = fromTree(tree);
 * console.log(originalData);
 * // returns
 * [
 *      {
 *          "id": 2,
 *          "parentId": null
 *      },
 *      {
 *          "id": 5,
 *          "parentId": 2
 *      },
 *      {
 *          "id": 6,
 *          "parentId": 2
 *      }
 * ]
 */
export function fromTree(tree: TreeNode[], parentId: number | null = null): Node[] {
    const result: Node[] = [];

    tree.forEach(node => {
        const { id, children, ...otherData } = node;
        result.push({ id, parentId, ...otherData });
        result.push(...fromTree(children, id));
    });

    return result;
}

/**
 * 스크롤이 존재하는 상위 element를 찾아 반환
 * // TODO: unit test 에서는 scroll 좌표를 제대로 찾지 못하므로 playwright 를 사용하여 테스트 해야함
 * @param element 스크롤이 존재하는 상위 element를 찾을 element
 * @returns HTMLElement | null
 */
export function findScrollingParent(element: HTMLElement | null): HTMLElement | null {
    while (element) {
        const style = getComputedStyle(element);

        const overflowX = style.overflowX;
        const overflowY = style.overflowY;

        const isScrollableX = (overflowX === 'scroll' || overflowX === 'auto') && element.scrollWidth > element.clientWidth;
        const isScrollableY = (overflowY === 'scroll' || overflowY === 'auto') && element.scrollHeight > element.clientHeight;

        if (isScrollableX || isScrollableY) {
            return element;
        }

        element = element.parentElement;
    }
    return null; // 스크롤이 존재하는 상위 element가 없는 경우 null 반환
}

/**
 * 화면에 보이는지 여부를 반환
 * // TODO: unit test 에서는 scroll 좌표를 제대로 찾지 못하므로 playwright 를 사용하여 테스트 해야함
 * @param el 화면에 보이는지 여부를 확인할 엘리먼트
 * @returns boolean 화면에 보이는지 여부
 */
export function isElementInViewport(el: HTMLElement) {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
        return false; // 브라우저 환경이 아닐 경우 false 반환
    }

    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

/**
 * 화면에 보이는지 여부를 반환
 * // TODO: unit test 에서는 scroll 좌표를 제대로 찾지 못하므로 playwright 를 사용하여 테스트 해야함
 * @param el 화면에 보이는지 여부를 확인할 엘리먼트
 * @param threshold 표시 비율 (0-100)
 * @returns boolean 화면에 보이는지 여부
 */
export function isElementVisibleInViewportByThreshold(element: HTMLElement, threshold: number): boolean {
    const elementRect = element.getBoundingClientRect();

    const visibleTop = Math.max(elementRect.top, 0);
    const visibleBottom = Math.min(elementRect.bottom, window.innerHeight);
    const visibleHeight = visibleBottom - visibleTop;

    const visibleLeft = Math.max(elementRect.left, 0);
    const visibleRight = Math.min(elementRect.right, window.innerWidth);
    const visibleWidth = visibleRight - visibleLeft;

    const visibleAreaPercent = ((visibleHeight * visibleWidth) / (elementRect.height * elementRect.width)) * 100;

    return visibleAreaPercent >= threshold;
}

/**
 * 요소가 스크롤된 위치에서 threshold% 이상 보이는지 확인합니다.
 * // TODO: unit test 에서는 scroll 좌표를 제대로 찾지 못하므로 playwright 를 사용하여 테스트 해야함
 * @param element 확인하려는 요소
 * @param threshold 표시 비율 (0-100)
 * @returns boolean
 */
export function isElementVisibleByThreshold(element: HTMLElement, threshold: number): boolean {
    const scrollingParent = findScrollingParent(element);

    if (scrollingParent) {
        const elementRect = element.getBoundingClientRect();
        const parentRect = scrollingParent.getBoundingClientRect();

        const visibleTop = Math.max(elementRect.top, parentRect.top);
        const visibleBottom = Math.min(elementRect.bottom, parentRect.bottom);
        const visibleHeight = visibleBottom - visibleTop;

        const visibleLeft = Math.max(elementRect.left, parentRect.left);
        const visibleRight = Math.min(elementRect.right, parentRect.right);
        const visibleWidth = visibleRight - visibleLeft;

        const visibleAreaPercent = ((visibleHeight * visibleWidth) / (elementRect.height * elementRect.width)) * 100;

        return visibleAreaPercent >= threshold;
    } else {
        return isElementVisibleInViewportByThreshold(element, threshold);
    }
}

/**
 * 폰트 사이즈를 확인하여 올바른 값이면 반환하고, 아니면 null을 반환
 */
export function checkHtmlFontSize(value: string): string | null {
    const regex = /^\d+(\.\d+)?(px|pt|em|rem)$/; // 순수 숫자만 있는것도 허용하지 않도록 수정
    const match = value.match(regex);
    if (match) {
        return match[0];
    }
    return null;
}
