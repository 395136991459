import { Extension } from '@tiptap/core';

// Tab 키를 눌렀을 때 에디터 밖으로 탭키가 벗어나는 것을 방지하기 위한 Extension
const TabKeyPrevent = Extension.create({
    name: 'customTab',

    addKeyboardShortcuts() {
        return {
            Tab: () => {
                return true;
            },
            'Shift-Tab': () => {
                return true;
            },
        };
    },
});

export default TabKeyPrevent;
