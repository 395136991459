<script lang="ts">
    import type { NodeViewProps } from '@tiptap/core';
    import { NodeViewContent, NodeViewWrapper } from 'svelte-tiptap';
    import { twMerge } from 'tailwind-merge';

    export let node: NodeViewProps['node'];
    export let selected: NodeViewProps['selected'] = false;
    export let editor: NodeViewProps['editor'];
    export let updateAttributes: NodeViewProps['updateAttributes'];

    let focusedBlock = false;
    $: node.attrs['focused-input'], blockBgAnimation();

    let timeout;
    function blockBgAnimation() {
        if (node.attrs['focused-input']) {
            if (timeout) clearTimeout(timeout);
            focusedBlock = true;
            timeout = setTimeout(() => {
                focusedBlock = false;
            }, 2000);
        }
    }
</script>

<NodeViewWrapper class={twMerge('svelte-component', selected && 'selected')}>
    <p
        class="bhsn-block-reference focus-visible:ring-yellow-50"
        class:bg-yellow-50={focusedBlock}
        class:duration-500={!focusedBlock}
        data-drag-handle
        focused-input={node.attrs['focused-input']}
        contenteditable={editor.options.editable}
    >
        {#if !node.content.size}
            {node.attrs['data-name']}
        {/if}
        <!-- <NodeViewContent as="div" data-drag-handle contenteditable={editor.options.editable} /> -->
        <NodeViewContent as="div" contenteditable={editor.options.editable} />
    </p>
</NodeViewWrapper>
