import type { BhsnReferenceOptions } from './BhsnBlockReferenceExtension';
import { Node, mergeAttributes } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';

export const BhsnBlockReferenceUnlinkExtension = Node.create<BhsnReferenceOptions>({
    name: 'blockReference',
    group: 'block',
    content: 'block*',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-block-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-block-reference', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        return {
            updateBlockReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema, apply } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;
                    const ids = attributes.map(attr => attr.id);
                    const nodeInfos = [];

                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType && ids.includes(node.attrs['data-id'])) {
                            nodeInfos.push({ node, pos });
                        }
                    });

                    // Process nodes in reverse order to avoid the position out of range issue
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { id, value } = attributes.find(attr => attr.id === node.attrs['data-id']) || {};

                        if (id) {
                            let fragment;
                            if (value) {
                                if (typeof value === 'object') {
                                    fragment = Fragment.fromJSON(schema, value);
                                } else {
                                    fragment = Fragment.fromJSON(schema, [
                                        {
                                            type: 'paragraph',
                                            content: [{ type: 'text', text: value }],
                                        },
                                    ]);
                                }
                            } else {
                                fragment = Fragment.empty;
                            }

                            const updatedNode = node.copy(fragment);
                            tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                        }
                    }

                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputBlockReferenceContent:
                id =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });

                    // 선택한 reference 노드에 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType && id === node.attrs['data-id']) {
                            const { attrs } = node;
                            tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputBlockReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const blockReferenceNodeType = schema.nodes.blockReference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === blockReferenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getExistAllBlockReferenceIds:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.blockReference;
                    const referenceIds: string[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            referenceIds.push(node.attrs['data-id']);
                        }
                    });
                    return referenceIds;
                },
            isExistEmptyBlockReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.blockReference;
                    const emptyReferenceNodePositions: number[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType && !node.content.size) {
                            emptyReferenceNodePositions.push(pos);
                        }
                    });
                    return emptyReferenceNodePositions.length > 0;
                },
        };
    },
});
