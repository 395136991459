<script lang="ts">
    import type { NodeViewProps } from '@tiptap/core';
    import { NodeViewContent, NodeViewWrapper } from 'svelte-tiptap';
    import { twMerge } from 'tailwind-merge';

    export let node: NodeViewProps['node'];
    export let selected: NodeViewProps['selected'] = false;
    export let editor: NodeViewProps['editor'];
    export let updateAttributes: NodeViewProps['updateAttributes'];
</script>

<NodeViewWrapper class={twMerge('svelte-component inline', selected && 'selected')}>
    <span
        class="bhsn-reference rounded p-0.5 font-bold"
        class:bg-yellow-100={!node.attrs['focused-input']}
        class:ring-yellow-100={!node.attrs['focused-input']}
        class:bg-orange-300={node.attrs['focused-input']}
        class:ring-orange-300={node.attrs['focused-input']}
        class:ring={selected}
        data-drag-handle
        focused-input={node.attrs['focused-input']}
        contenteditable="false"
    >
        {#if !node.content.size}
            {node.attrs['data-name']}
        {/if}
        <NodeViewContent as="span" />
    </span>
</NodeViewWrapper>
