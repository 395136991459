import { Extension } from '@tiptap/core';
import { Node, mergeAttributes } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
// TODO: 테이블 관련 기능은 아직 미완성이라 사용하지 않음, 존재하는 table extension의 경우 이슈가 존재 https://github.com/frappe/wiki/issues/164
// import Table from '../extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import { TextStyleExtended } from './TextStyle';
import { Color } from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Code from '@tiptap/extension-code';
import TextAlign from '@tiptap/extension-text-align';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import HardBreak from '@tiptap/extension-hard-break';
import { Indent } from './Indent';
import { InsertedText, DeletedText } from './Diff';
import TabKeyPrevent from './TabKeyPrevent';
import { Comment } from '../extension-comment/Comment';

export interface WordStarterPackOptions {
    [key: string]: any;
}

function ptToPx(pt: number) {
    return (pt * 96) / 72;
}

const WordStarterPack = Extension.create<WordStarterPackOptions>({
    name: 'wordStarterPack',

    addExtensions() {
        const extensions = [
            TabKeyPrevent,
            StarterKit.configure({
                document: false,
                heading: false,
                text: false,
                bulletList: false,
                bold: false,
                code: false,
                italic: false,
                strike: false,
                paragraph: false,
                listItem: false,
                orderedList: false,
                hardBreak: false,
            }),
            Document,
            Paragraph.configure({
                HTMLAttributes: {
                    class: 'm-0 min-h-[1em] whitespace-pre-wrap',
                },
            }),
            Heading.extend({
                levels: [1, 2, 3, 4, 5, 6],
                renderHTML({ node, HTMLAttributes }) {
                    const level = this.options.levels.includes(node.attrs.level) ? node.attrs.level : this.options.levels[0];
                    const classes: any = {
                        1: 'text-4xl',
                        2: 'text-2xl',
                        3: 'text-xl',
                        4: 'text-lg',
                        5: 'text-md',
                        6: 'text-sm',
                    };
                    return [
                        `h${level}`,
                        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                            class: `${classes[level]}`,
                        }),
                        0,
                    ];
                },
            }).configure({ levels: [1, 2, 3, 4, 5, 6] }),
            Text,
            TextStyleExtended,
            Color,
            Bold,
            Italic,
            Strike.extend({
                parseHTML() {
                    return [
                        {
                            tag: 's',
                        },
                        {
                            tag: 'strike',
                        },
                        {
                            style: 'text-decoration',
                            consuming: false,
                            getAttrs: style => ((style as string).includes('line-through') ? {} : false),
                        },
                    ];
                },
            }),
            Underline,
            Subscript,
            Superscript,
            Code,
            TextAlign.configure({
                types: ['heading', 'paragraph', 'bulletList', 'orderedList'],
            }),
            Table.configure({
                resizable: true,
                HTMLAttributes: {
                    class: 'relative border-collapse table-fixed m-0 overflow-hidden max-w-full',
                },
            }),
            TableRow.configure({
                HTMLAttributes: {
                    class: 'relative',
                },
            }),
            TableHeader.configure({
                HTMLAttributes: {
                    class: 'relative',
                },
            }),
            TableCell.extend({
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        colwidth: {
                            default: null,
                            parseHTML: element => {
                                const colwidth = element.getAttribute('colwidth');
                                const value = colwidth ? [parseInt(colwidth, 10)] : null;
                                return value;
                            },
                            renderHTML: attributes => {
                                return {
                                    style: `width: ${ptToPx(attributes.colwidth)}px;`, // TODO: 편집시 drag 기능이 상위 colgroup 의 width 를 무시하고 자신의 width 를 따라가는 문제가 있음
                                    colwidth: attributes.colwidth,
                                };
                            },
                        },
                    };
                },
            }).configure({
                HTMLAttributes: {
                    class: 'relative border border-gray-300 px-1',
                },
            }),
            BulletList.extend({
                priority: 900,
                // parseHTML() {
                //     return [
                //         { tag: 'div[data-BulletList] > ul' },
                //     ];
                // },
                // renderHTML({ node, HTMLAttributes }) {
                //     // console.log('this.options.HTMLAttributes', this.options, node, HTMLAttributes);
                //     const { textAlign, ...restHTMLAttributes } = HTMLAttributes;
                //     return [
                //         'div',
                //         { style: `text-align: ${textAlign}`, class: 'bulletListWrapper', 'data-BulletList': true },
                //         ['ul', mergeAttributes(this.options.HTMLAttributes, restHTMLAttributes), 0],
                //     ];
                // },
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        listStyleType: {
                            default: 'disc',
                            parseHTML: element => {
                                return element.style.listStyleType || 'disc';
                            },
                            renderHTML: attributes => {
                                return {
                                    ...attributes,
                                    style: `list-style-type: ${attributes.listStyleType}`,
                                };
                            },
                        },
                    };
                },
            }).configure({
                HTMLAttributes: {
                    class: 'px-4 list-disc',
                },
                keepAttributes: true,
                itemTypeName: 'listItem',
            }),
            OrderedList.extend({
                priority: 900,
                // parseHTML() {
                //     return [
                //         { tag: 'div[data-OrderedList] > ol' },
                //     ];
                // },
                // renderHTML({ node, HTMLAttributes }) {
                //     const { textAlign, ...restHTMLAttributes } = HTMLAttributes;
                //     return [
                //         'div',
                //         mergeAttributes({ style: textAlign ? `text-align: ${textAlign}` : undefined, class: 'orderedListWrapper', 'data-OrderedList': true }),
                //         ['ol', mergeAttributes(this.options.HTMLAttributes, restHTMLAttributes), 0],
                //     ];
                // },
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        listStyleType: {
                            default: 'decimal',
                            parseHTML: element => {
                                return element.style.listStyleType || 'decimal';
                            },
                            renderHTML: attributes => {
                                return {
                                    ...attributes,
                                    style: `list-style-type: ${attributes.listStyleType}`,
                                };
                            },
                        },
                    };
                },
            }).configure({
                HTMLAttributes: {
                    class: 'px-4 list-decimal',
                },
                keepAttributes: true,
                itemTypeName: 'listItem',
            }),
            ListItem,
            Indent,
            HardBreak,
            InsertedText.configure({
                HTMLAttributes: {
                    class: 'bg-blue-200 text-blue-700 no-underline p-1',
                },
            }),
            DeletedText.configure({
                HTMLAttributes: {
                    class: 'bg-red-200 text-red-700 line-through p-1',
                },
            }),
            Comment.configure({
                HTMLAttributes: {
                    class: 'word-comment bg-blue-100',
                },
                onCommentActivated: commentId => {
                    // console.log('commentId', commentId);
                },
            }),
        ];
        return extensions;
    },
});

export default WordStarterPack;
