import { Node, mergeAttributes } from '@tiptap/core';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';
import BhsnReferenceComponent from './BhsnReferenceComponent.svelte';
import { Fragment } from '@tiptap/pm/model';

export interface BhsnReferenceOptions {
    HTMLAttributes: Record<string, any>;
}
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        reference: {
            toggleReference: () => ReturnType;
            updateReferenceContent: (
                attributes: {
                    [x: string]: any;
                }[],
            ) => ReturnType;
            focusedInputReferenceContent: (id: string) => ReturnType;
            blurInputReferenceContent: () => ReturnType;
            getExistAllReferenceIds: () => string[];
            isExistEmptyReference: () => ReturnType;
        };
    }
}

export const BhsnReferenceExtension = Node.create<BhsnReferenceOptions>({
    name: 'reference',
    group: 'inline',
    content: 'text*',
    atom: true,
    draggable: true, // Optional: to make the node draggable
    inline: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
            'focused-input': {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-reference', HTMLAttributes, 0];
    },

    addCommands() {
        return {
            toggleReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { selection, schema, doc } = state;
                    const { from, to } = selection;
                    const referenceNodeType = schema.nodes.reference;

                    const referenceNodePositions: number[] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (node.type === referenceNodeType) {
                            referenceNodePositions.push(pos);
                        }
                    });

                    if (referenceNodePositions.length) {
                        // 'reference' 노드를 텍스트로 변환
                        referenceNodePositions.forEach(pos => {
                            const node = tr.doc.nodeAt(pos);
                            const textContent = node!.attrs['data-id'];
                            tr.replaceWith(pos, pos + node!.nodeSize, schema.text(textContent));
                        });
                    } else {
                        // 선택된 텍스트를 가져와 'data-id' 속성에 설정한 뒤 'reference' 노드로 변환
                        const textContent = doc.textBetween(from, to);
                        const referenceNode = referenceNodeType.create({ 'data-id': textContent, 'data-name': textContent });
                        tr.replaceWith(from, to, referenceNode);
                    }

                    if (dispatch) dispatch(tr);
                    return true;
                },
            // 특정 id를 포함하는 값을 받았을때 data-id이 같은 값을 child content로 추가합니다.
            updateReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const ids = attributes.map(attr => attr.id);
                    const nodeInfos = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType && ids.includes(node.attrs['data-id'])) {
                            nodeInfos.push({ node, pos });
                        }
                    });

                    // Process nodes in reverse order to avoid the position out of range issue
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { id, value } = attributes.find(attr => attr.id === node.attrs['data-id']) || {};

                        if (id) {
                            let fragment;
                            if (value) {
                                const content = schema.text(value);
                                fragment = Fragment.from(content);
                            } else {
                                fragment = Fragment.empty;
                            }

                            const updatedNode = node.copy(fragment);
                            tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                        }
                    }

                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputReferenceContent:
                id =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });

                    // 선택한 reference 노드에 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType && id === node.attrs['data-id']) {
                            const { attrs } = node;
                            tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getExistAllReferenceIds:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const referenceIds: string[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            referenceIds.push(node.attrs['data-id']);
                        }
                    });
                    return referenceIds;
                },
            isExistEmptyReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const emptyReferenceNodePositions: number[] = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType && !node.content.size) {
                            emptyReferenceNodePositions.push(pos);
                        }
                    });
                    return emptyReferenceNodePositions.length > 0;
                },
        };
    },
    addNodeView() {
        return SvelteNodeViewRenderer(BhsnReferenceComponent);
    },
});
