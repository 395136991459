import { Mark, mergeAttributes, type Range } from '@tiptap/core';
import { DOMSerializer, type Mark as PMMark } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { intersection, isEqual } from 'lodash-es';
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        comment: {
            setComment: (commentId: string) => ReturnType;
            unsetComment: (commentId: string) => ReturnType;
            unsetCommentWithRange: (commentId: string) => ReturnType;
            removeAllCommentsWithId: (commentId: string) => ReturnType;
            removeAllComments: () => ReturnType;
            addClassToComment: (commentId: string, className: string) => ReturnType;
            removeClassFromComment: (commentId: string, className: string) => ReturnType;
        };
    }
}

export interface MarkWithRange {
    mark: PMMark;
    range: Range;
}

export interface CommentOptions {
    HTMLAttributes: Record<string, any>;
    onCommentActivated: (commentIds: string[] | null) => void;
}

export interface CommentStorage {
    activeCommentIds: string[] | null;
}

const COMMENT_ATTR_NAME = 'data-comment-ids';

/**
 * 에디터 내부의 모든 'comment' 마크 속성을 찾아서 리턴합니다.
 * @param state
 * @returns {Array} 'comment' 마크의 속성 배열
 * @example
 * getAllComments(state);
 * [
 *   {
 *     attrs: { commentIds: ['comment-1'] },
 *     from: 0,
 *     to: 10,
 *   },
 *   {
 *     attrs: { commentIds: ['comment-2'] },
 *     from: 10,
 *     to: 20,
 *   },
 * ]
 */
export function getAllComments(state) {
    const { doc, schema } = state;
    const { comment } = schema.marks;
    const comments: any[] = [];

    // 문서의 모든 노드를 순회
    doc.descendants((node, pos) => {
        node.marks.forEach(mark => {
            if (mark.type === comment) {
                // 'comment' 마크의 속성 수집
                comments.push({
                    attrs: mark.attrs,
                    from: pos,
                    to: pos + node.nodeSize,
                });
            }
        });
    });

    // 동일한 commentIds 속성을 가진 마크를 병합
    const mergedComments: any[] = [];
    comments.forEach(comment => {
        const mergedComment = mergedComments.find(mergedComment => isEqual(mergedComment.attrs.commentIds, comment.attrs.commentIds));
        if (mergedComment) {
            mergedComment.from = Math.min(mergedComment.from, comment.from);
            mergedComment.to = Math.max(mergedComment.to, comment.to);
        } else {
            mergedComments.push(comment);
        }
    });

    // 수집된 'comment' 마크의 속성 반환
    return mergedComments;
}

// 에디터 내부의 모든 comment 마크 속성을 가진 commentId 의 리스트를 리턴합니다.
export function getAllCommentId(state) {
    const comments = getAllComments(state);
    return [...new Set(comments.map(comment => comment.attrs?.commentIds).flat())];
}

export function getAllCommentsWithText(state) {
    const comments: any[] = [];
    const { doc, schema } = state;
    const { comment } = schema.marks;

    let isFirstBlockInListItem = false; // 리스트 항목의 첫 번째 블록 노드인지 추적

    doc.descendants((node, pos) => {
        if (node.type.name === 'listItem') {
            isFirstBlockInListItem = true; // 새로운 listItem 시작
        } else if (node.isBlock) {
            if (!isFirstBlockInListItem) {
                // listItem의 첫 번째 블록이 아니면 줄바꿈 추가
                Object.values(comments).forEach(entry => {
                    entry.innerText += '\n';
                });
            }
            isFirstBlockInListItem = false; // 다음 노드는 첫 번째 블록이 아님
        }

        if (node.type.name === 'hardBreak') {
            // hardBreak 노드에 도달하면 줄바꿈 추가
            Object.values(comments).forEach(entry => {
                entry.innerText += '\n';
            });
        }

        node.marks
            .filter(mark => mark.type === comment)
            .forEach(mark => {
                mark.attrs.commentIds.forEach(commentId => {
                    let existingComment = comments.find(c => c.commentId === commentId);

                    if (!existingComment) {
                        existingComment = { commentId, innerText: '' };
                        comments.push(existingComment);
                    }

                    if (node.isText) {
                        existingComment.innerText += node.textContent;
                    }
                });
            });
    });

    // 결과를 trim 하여 반환
    return comments.map(comment => ({
        commentId: comment.commentId,
        innerText: comment.innerText.trim(),
    }));
}

export function removeCommentMarkFromDOM(dom) {
    const comments = dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}]`);
    comments.forEach(comment => {
        // 마크를 제거하기 위해 span 태그의 내용을 부모 노드에 직접 삽입
        while (comment.firstChild) {
            comment.parentNode.insertBefore(comment.firstChild, comment);
        }
        // 이제 빈 span 태그를 제거
        comment.parentNode.removeChild(comment);
    });
}

export const Comment = Mark.create<CommentOptions, CommentStorage>({
    name: 'comment',

    addOptions() {
        return {
            HTMLAttributes: {},
            onCommentActivated: () => {},
        };
    },

    addAttributes() {
        return {
            commentIds: {
                default: null,
                parseHTML: el => (el as HTMLSpanElement).getAttribute(COMMENT_ATTR_NAME)?.trim().split(',') || null,
                renderHTML: attrs => ({ [COMMENT_ATTR_NAME]: attrs.commentIds?.join(',') }),
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `span[${COMMENT_ATTR_NAME}]`,
                getAttrs: el => !!(el as HTMLSpanElement).getAttribute(COMMENT_ATTR_NAME)?.trim().split(',') && null,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    onSelectionUpdate() {
        const { $from } = this.editor.state.selection;
        const marks = $from.marks();

        if (!marks.length) {
            this.storage.activeCommentIds = null;
            this.options.onCommentActivated(this.storage.activeCommentIds);
            return;
        }

        const commentMark = this.editor.schema.marks.comment;
        const activeCommentMark = marks.find(mark => mark.type === commentMark);
        this.storage.activeCommentIds = activeCommentMark?.attrs.commentIds || null;
        this.options.onCommentActivated(this.storage.activeCommentIds);
    },

    addStorage() {
        return {
            activeCommentIds: null,
        };
    },

    addCommands() {
        return {
            setComment:
                commentId =>
                ({ tr, state, dispatch }) => {
                    const { comment } = state.schema.marks;
                    const { from, to } = state.selection;

                    tr.doc.nodesBetween(from, to, (node, pos) => {
                        if (!node.isText) return;

                        const nodeFrom = Math.max(pos, from);
                        const nodeTo = Math.min(pos + node.nodeSize, to);

                        const overlappingMarks = node.marks.filter(mark => mark.type === comment);

                        if (overlappingMarks.length) {
                            overlappingMarks.forEach(mark => {
                                const newCommentIds = Array.from(new Set([...mark.attrs.commentIds, commentId]));
                                const markFrom = Math.max(nodeFrom, pos);
                                const markTo = Math.min(nodeTo, pos + node.nodeSize);

                                tr.addMark(markFrom, markTo, comment.create({ commentIds: newCommentIds }));
                            });
                        } else {
                            tr.addMark(nodeFrom, nodeTo, comment.create({ commentIds: [commentId] }));
                        }
                    });

                    if (dispatch) dispatch(tr);
                    return true;
                },
            unsetComment:
                commentId =>
                ({ tr, state, dispatch }) => {
                    const { comment } = state.schema.marks;

                    state.doc.descendants((node, pos) => {
                        if (!node.isInline) return;

                        node.marks.forEach(mark => {
                            if (mark.type === comment) {
                                const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);
                                const start = pos;
                                const end = pos + node.nodeSize;

                                if (newCommentIds.length) {
                                    tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                } else {
                                    tr.removeMark(start, end, mark);
                                }
                            }
                        });
                    });

                    if (dispatch) dispatch(tr);
                    return true;
                },
            unsetCommentWithRange:
                commentId =>
                ({ tr, state, dispatch }) => {
                    const { comment } = state.schema.marks;
                    const { from, to } = state.selection;

                    tr.doc.nodesBetween(from, to, (node, pos) => {
                        const start = Math.max(from, pos);
                        const end = Math.min(to, pos + node.nodeSize);

                        node.marks.forEach(mark => {
                            if (mark.type === comment) {
                                const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);

                                if (newCommentIds.length) {
                                    tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                } else {
                                    tr.removeMark(start, end, mark);
                                }
                            }
                        });
                    });
                    tr.setSelection(TextSelection.create(tr.doc, to, to));

                    if (dispatch) dispatch(tr);
                    return true;
                },
            removeAllCommentsWithId:
                commentId =>
                ({ tr, state, dispatch }) => {
                    const { comment } = state.schema.marks;

                    state.doc.descendants((node, pos) => {
                        if (!node.isText) return;

                        node.marks.forEach(mark => {
                            if (mark.type === comment) {
                                // 해당 commentId를 제외한 새로운 ID 배열 생성
                                const newCommentIds = mark.attrs.commentIds.filter(id => id !== commentId);

                                // 만약 새로운 ID 배열이 비어 있다면, 마크를 완전히 제거
                                if (newCommentIds.length === 0) {
                                    const start = pos;
                                    const end = pos + node.nodeSize;
                                    tr.removeMark(start, end, mark);
                                }
                                // 그렇지 않다면 새로운 ID 배열로 마크 업데이트
                                else if (newCommentIds.length !== mark.attrs.commentIds.length) {
                                    const start = pos;
                                    const end = pos + node.nodeSize;
                                    tr.removeMark(start, end, mark);
                                    tr.addMark(start, end, comment.create({ commentIds: newCommentIds }));
                                }
                            }
                        });
                    });

                    if (dispatch) dispatch(tr);
                    return true;
                },
            removeAllComments:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const { comment } = schema.marks;

                    // 문서의 모든 노드를 순회
                    doc.descendants((node, pos) => {
                        if (!node.isInline) return;

                        // 각 노드에서 'comment' 마크를 찾아 제거
                        node.marks.forEach(mark => {
                            if (mark.type === comment) {
                                const start = pos;
                                const end = pos + node.nodeSize;
                                tr.removeMark(start, end, mark);
                            }
                        });
                    });

                    // 변경 사항 적용
                    if (dispatch) dispatch(tr);
                    return true;
                },
            addClassToComment:
                (commentId, className) =>
                ({ tr, state, dispatch, view }) => {
                    const commentSpans = view.dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}*="${commentId}"]`);
                    commentSpans.forEach(span => span.classList.add(className));
                    if (dispatch) dispatch(tr);
                    return true;
                },

            removeClassFromComment:
                (commentId, className) =>
                ({ tr, state, dispatch, view }) => {
                    const commentSpans = view.dom.querySelectorAll(`span[${COMMENT_ATTR_NAME}*="${commentId}"]`);
                    commentSpans.forEach(span => span.classList.remove(className));
                    if (dispatch) dispatch(tr);
                    return true;
                },
        };
    },
    addProseMirrorPlugins() {
        const pluginKey = new PluginKey('commentHover');
        const hoverPlugin = new Plugin({
            key: pluginKey,
            state: {
                init: (_, { tr }) => DecorationSet.empty,
                apply: (tr, oldValue, oldState, newState) => {
                    // 마우스 오버로 인해 새로운 Decoration이 추가되었는지 확인합니다.
                    const hoveredCommentIds: string[] = tr.getMeta(pluginKey);
                    if (hoveredCommentIds) {
                        // 마우스 오버된 Comment ID에 대한 Decoration을 추가합니다.
                        const decorations: Decoration[] = [];
                        newState.doc.descendants((node, pos) => {
                            if (node.isText) {
                                const marks = node.marks.filter(mark => mark.type === this.type.schema.marks.comment && intersection(mark.attrs.commentIds, hoveredCommentIds).length > 0);
                                marks.forEach(mark => {
                                    const from = pos;
                                    const to = pos + node.nodeSize;
                                    const decoration = Decoration.inline(from, to, { class: 'active-comment bg-blue-200' });
                                    decorations.push(decoration);
                                });
                            }
                        });
                        return DecorationSet.create(newState.doc, decorations);
                    }
                    return oldValue;
                },
            },
            props: {
                handleDOMEvents: {
                    copy: (view, event) => {
                        const { selection, doc } = view.state;
                        const content = selection.content().content;

                        // 선택된 컨텐츠를 DOM으로 변환
                        const dom = DOMSerializer.fromSchema(view.state.schema).serializeFragment(content);

                        // DOM에서 comment 마크 제거
                        removeCommentMarkFromDOM(dom);

                        // 새로운 DOM을 문자열로 변환하여 클립보드에 복사
                        const serializer = new XMLSerializer();
                        const copyText = serializer.serializeToString(dom);
                        event.clipboardData?.setData('text/plain', copyText);
                        event.clipboardData?.setData('text/html', copyText);
                        event.preventDefault(); // 기본 복사 동작 방지
                        return true;
                    },
                },
                decorations(state) {
                    return this.getState(state);
                },
            },
            view(view) {
                let prevCommentIds: string[] = [];
                const handleMouseOver = event => {
                    const { target } = event;
                    if (target.nodeType === Node.ELEMENT_NODE) {
                        const span = target.closest(`span[${COMMENT_ATTR_NAME}]`);
                        let newCommentIds = [];

                        if (span) {
                            newCommentIds = span.getAttribute(COMMENT_ATTR_NAME).split(',');
                        }

                        // 현재 메타 데이터와 새로운 메타 데이터가 다를 경우에만 트랜잭션 디스패치
                        if (!isEqual(prevCommentIds, newCommentIds)) {
                            prevCommentIds = newCommentIds;
                            view.dispatch(view.state.tr.setMeta(pluginKey, newCommentIds));
                        }
                    }
                };
                view.dom.addEventListener('mouseover', handleMouseOver);
                return {
                    destroy() {
                        view.dom.removeEventListener('mouseover', handleMouseOver);
                    },
                };
            },
        });

        function createVerticalLineDecoration(pos, options = {}) {
            return Decoration.widget(
                pos,
                () => {
                    const line = document.createElement('span');
                    line.className = 'border-l-2 border-blue-600 w-0';
                    return line;
                },
                options,
            );
        }

        // 선택된 메모영역의 시작과 끝에 수직선을 추가하는 플러그인
        const verticalLineDecorationPlugin = new Plugin({
            props: {
                decorations: state => {
                    const decorations: Decoration[] = [];
                    const { from, to } = state.selection;
                    const commentRanges = new Map();
                    const processedPositions = new Set();

                    // 문서를 순회하며 각 commentId에 대한 시작과 끝 위치를 찾음
                    state.doc.descendants((node, pos) => {
                        if (node.isText) {
                            node.marks.forEach(mark => {
                                if (mark.type === this.type && mark.attrs.commentIds) {
                                    mark.attrs.commentIds.forEach(commentId => {
                                        if (!commentRanges.has(commentId)) {
                                            commentRanges.set(commentId, { start: pos, end: pos + node.nodeSize });
                                        } else {
                                            const range = commentRanges.get(commentId);
                                            range.start = Math.min(range.start, pos);
                                            range.end = Math.max(range.end, pos + node.nodeSize);
                                        }
                                    });
                                }
                            });
                        }
                    });

                    // 커서 위치에 있는 모든 commentId에 대한 시작과 끝 위치에 수직선 추가
                    commentRanges.forEach(({ start, end }) => {
                        if (start <= from && end >= to) {
                            if (!processedPositions.has(start)) {
                                decorations.push(createVerticalLineDecoration(start, { side: -1 }));
                                processedPositions.add(start);
                            }
                            if (!processedPositions.has(end)) {
                                decorations.push(createVerticalLineDecoration(end, { side: 0 }));
                                processedPositions.add(end);
                            }
                        }
                    });

                    return DecorationSet.create(state.doc, decorations);
                },
            },
        });

        return [hoverPlugin, verticalLineDecorationPlugin];
    },
});
