import { checkHtmlFontSize } from '$lib/utils';
import TextStyle from '@tiptap/extension-text-style';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontSize: {
            /**
             * Set the font size
             */
            setFontSize: (size: string) => ReturnType;
            /**
             * Unset the font size
             */
            unsetFontSize: () => ReturnType;
        };
        backgroundColor: {
            /**
             * Set the background color
             */
            setBackgroundColor: (color: string) => ReturnType;
            /**
             * Unset the background color
             */
            unsetBackgroundColor: () => ReturnType;
        };
    }
}

export const TextStyleExtended = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            fontSize: {
                default: null,
                parseHTML: element => element.style.fontSize.replace('px', ''),
                renderHTML: attributes => {
                    if (!attributes['fontSize']) {
                        return {};
                    }
                    return {
                        style: `font-size: ${checkHtmlFontSize(attributes['fontSize']) ? attributes['fontSize'] : `${parseInt(attributes['fontSize'])}pt`}`,
                    };
                },
            },
            backgroundColor: {
                default: null,
                parseHTML: element => element.style.backgroundColor,
                renderHTML: attributes => {
                    if (!attributes['backgroundColor']) {
                        return {};
                    }
                    return {
                        style: `background-color: ${attributes['backgroundColor']}`,
                    };
                },
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setFontSize:
                fontSize =>
                ({ commands }) => {
                    return commands.setMark(this.name, { fontSize: fontSize });
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    return chain().setMark(this.name, { fontSize: null }).removeEmptyTextStyle().run();
                },
            setBackgroundColor:
                color =>
                ({ commands }) => {
                    return commands.setMark(this.name, { backgroundColor: color });
                },
            unsetBackgroundColor:
                () =>
                ({ chain }) => {
                    return chain().setMark(this.name, { backgroundColor: null }).removeEmptyTextStyle().run();
                },
        };
    },
});
